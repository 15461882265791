<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                            <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="org_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="guestHouseData.org_id"
                                :options="orgList"
                                id="org_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="office_type_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                plain
                                v-model="guestHouseData.office_type_id"
                                :options="officeTypeList"
                                id="office_type_id"
                                :state="errors[0] ? false : (valid ? true : null)"
                                >
                                <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="office_id"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="guestHouseData.office_id"
                                    :options="officeList"
                                    id="office_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                    <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Gender" vid="gender_id" v-if="$store.state.Auth.authUser.org_id !== 9 && guestHouseData.org_id !== 9">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="gender_id"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('globalTrans.gender')}}
                                </template>
                                <b-form-radio-group
                                id="gender_id"
                                v-model="guestHouseData.gender_id"
                                :options="genderList"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-radio-group>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Guest House (En)" vid="guest_house_en" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="guest_house_en"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.guest_house_en')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="guest_house_en"
                                v-model="guestHouseData.guest_house_en"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Guest House (Bn)" vid="guest_house_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="guest_house_bn"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.guest_house_bn')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="guest_house_bn"
                                v-model="guestHouseData.guest_house_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Wifi Name" vid="wifi_name">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="wifi_name"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.wifi_name')}}
                                </template>
                                <b-form-input
                                id="wifi_name"
                                v-model="guestHouseData.wifi_name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Wifi Password" vid="wifi_password">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="wifi_password"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.wifi_password')}}
                                </template>
                                <b-form-input
                                id="wifi_password"
                                v-model="guestHouseData.wifi_password"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="remarks"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.remarks_en')}}
                                    </template>
                                    <b-form-textarea
                                        rows="2"
                                        id="remarks"
                                        v-model="guestHouseData.remarks"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="remarks_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.remarks_bn')}}
                                    </template>
                                    <b-form-textarea
                                        rows="2"
                                        id="remarks_bn"
                                        v-model="guestHouseData.remarks_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { guestHouseStore, guestHouseUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getRoomTypeData()
      this.guestHouseData = tmp
    } else {
       this.guestHouseData = Object.assign({}, this.guestHouseData, {
        org_id: this.$store.state.dataFilters.orgId
      })
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      guestHouseData: {
        id: '',
        office_type_id: 0,
        org_id: 0,
        office_id: 0,
        gender_id: 0,
        guest_house_en: '',
        guest_house_bn: '',
        wifi_name: '',
        wifi_password: '',
        remarks: '',
        remarks_bn: '',
        status: 1
      },
      officeTypeList: [],
      officeList: []
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    genderList: function () {
      return this.$store.state.TrainingElearning.commonObj.genderList
    }
  },
  watch: {
    'guestHouseData.org_id': function (newValue) {
      this.officeTypeList = this.getofficeTypeList(newValue)
    },
    'guestHouseData.office_type_id': function (newValue) {
      this.officeList = this.getOfficeList(newValue)
    }
  },
  methods: {
    getRoomTypeData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getofficeTypeList (orgId) {
       const office = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
       if (orgId) {
         return office.filter(item => item.org_id === orgId)
       }
       return office
    },
    async createData () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.guestHouseData.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${guestHouseUpdate}/${this.id}`, this.guestHouseData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, guestHouseStore, this.guestHouseData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
